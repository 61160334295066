import { rootApi } from './rootApi'
import { COUPON_URL } from '../../constants'

export const couponApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    checkCoupon: builder.query({
      query: (coupon) => ({
        url: `${COUPON_URL}/${coupon}`,
      }),
    }),
  }),
})

export const { useCheckCouponQuery } = couponApi
