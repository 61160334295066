import { rootApi } from './rootApi'
import { STRIPE_URL } from '../../constants'

export const stripeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    stripeCheckout: builder.mutation({
      query: (orderItems) => ({
        url: `${STRIPE_URL}/create-checkout-session`,
        method: 'POST',
        body: { ...orderItems },
      }),
      keepUnusedDataFor: 5,
    }),
    stripeComplete: builder.query({
      query: (session_id) => ({
        url: `${STRIPE_URL}/checkout-success/${session_id}`,
      }),
    }),
  }),
})

export const { useStripeCheckoutMutation, useStripeCompleteQuery } = stripeApi
