export const updateCart = (state) => {
  // Calculate Items Price
  state.itemsPrice = state.cartItems.reduce(
    (acc, item) => acc + item.price * item.qty,
    0
  )

  // Calculate Items Shipping Price
  state.shippingPrice = state.shippingPrice

  // Calculate Items Total Price
  state.totalPrice =
    Number(state.itemsPrice) +
    Number(state.shippingPrice) -
    Number(state.discountPrice)

  localStorage.setItem('cart', JSON.stringify(state))

  return state
}
