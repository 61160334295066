import { useSelector } from 'react-redux'
import { useStripeCheckoutMutation } from '../store/apis/stripeApi'

const PayButton = ({ orderItems }) => {
  const { userInfo } = useSelector((state) => state.auth)
  const [stripeCheckout] = useStripeCheckoutMutation()

  const checkoutItems = { userId: userInfo._id, ...orderItems }

  const checkoutHandler = async () => {
    try {
      const res = await stripeCheckout(checkoutItems)

      if (res.data.url) {
        window.location.href = res.data.url
      }

      return
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <button className="btn btn-primary w-full" onClick={checkoutHandler}>
      クレジットカード決済する
    </button>
  )
}

export default PayButton
