import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

///////// before jwt expiration isseu //////////
// export const rootApi = createApi({
//   baseQuery: fetchBaseQuery({ baseUrl: '' }),
//   tagTypes: ['User', 'Order', 'Product'],
//   endpoints: (builder) => ({}),
// })

import { logout } from '../slices/authSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: '',
})

async function baseQueryWithAuth(args, api, extra) {
  const result = await baseQuery(args, api, extra)
  // Dispatch the logout action on 401.
  if (result.error && result.error.status === 401) {
    api.dispatch(logout())
  }
  return result
}

export const rootApi = createApi({
  baseQuery: baseQueryWithAuth, // Use the customized baseQuery
  tagTypes: ['Product', 'Order', 'User'],
  endpoints: (builder) => ({}),
})
