import { createSlice } from '@reduxjs/toolkit'
import { updateCart } from '../../utils/cartUtils'

const initialState = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : {
      cartItems: [],
      shippingAddress: {},
      paymentMethod: 'クレジットカード',
      discountPrice: 0,
      comment: '',
    }

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    tailorAddToCart: (state, action) => {
      const item = action.payload
      state.cartItems = [...state.cartItems, item]
      return updateCart(state)
    },
    tailorRepeatAddToCart: (state, action) => {
      const item = action.payload
      const existItem = state.cartItems.find(
        (x) =>
          x._id === item._id &&
          x.color === item.color &&
          x.neck === item.neck &&
          x.size === item.size &&
          x.style === item.style &&
          x.length === item.length &&
          x.sleeveLength === item.sleeveLength &&
          x.lib === item.lib &&
          x.pocket.pocketType === item.pocket.pocketType &&
          x.pocket.pocketPosition === item.pocket.pocketPosition
      )

      if (existItem) {
        state.cartItems = state.cartItems.map((x) =>
          x.cartId === existItem.cartId ? item : x
        )
      } else {
        state.cartItems = [...state.cartItems, item]
      }

      return updateCart(state)
    },
    addToCart: (state, action) => {
      const item = action.payload
      const existItem = state.cartItems.find(
        (x) =>
          x._id === item._id &&
          x.color === item.color &&
          x.neck === item.neck &&
          x.size === item.size &&
          x.style === item.style &&
          x.length === item.length &&
          x.sleeveLength === item.sleeveLength &&
          x.lib === item.lib &&
          x.pocket.pocketType === item.pocket.pocketType &&
          x.pocket.pocketPosition === item.pocket.pocketPosition
      )

      if (existItem) {
        state.cartItems = state.cartItems.map((x) =>
          x.cartId === existItem.cartId ? item : x
        )
      } else {
        state.cartItems = [...state.cartItems, item]
      }

      return updateCart(state)
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (x) => x.cartId !== action.payload
      )
      state.discountPrice = 0

      return updateCart(state)
    },
    saveShippingAddress: (state, action) => {
      state.shippingAddress = action.payload
      return updateCart(state)
    },
    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
      return updateCart(state)
    },
    saveShippingPrice: (state, action) => {
      state.shippingPrice = action.payload
      return updateCart(state)
    },
    saveComment: (state, action) => {
      state.comment = action.payload
      return updateCart(state)
    },
    clearCartItems: (state) => {
      state.cartItems = []
      state.comment = ''
      return updateCart(state)
    },
    applyDiscount: (state, action) => {
      state.discountPrice = action.payload
      return updateCart(state)
    },
    notApplyDiscount: (state, action) => {
      state.discountPrice = 0
      return updateCart(state)
    },
  },
})

export const {
  tailorAddToCart,
  tailorRepeatAddToCart,
  addToCart,
  removeFromCart,
  saveShippingAddress,
  savePaymentMethod,
  saveShippingPrice,
  saveComment,
  clearCartItems,
  applyDiscount,
  notApplyDiscount,
} = cartSlice.actions

export default cartSlice.reducer
