export const BASE_URL =
  process.env.NODE_ENV === 'production' ? 'http://localhost:5001' : ''

export const CUSTOMIZE_TSHIRTS_URL = '/api/customize-tshirts'
export const TAILOR_TSHIRTS_URL = '/api/tailor-tshirts'
export const SIZE_URL = '/api/tshirts-size'
export const USERS_URL = '/api/users'
export const ORDERS_URL = '/api/orders'
export const SHIPPING_URL = '/api/shipping-cost'
export const SENDMAIL_URL = '/api/send-mail'
export const STRIPE_URL = '/api/stripe'
export const FORGOTPASSWORD_URL = '/api/auth'
export const FABRICINDEX_URL = '/api/fabric-sample'
export const CHECKFORM_URL = '/api/checkform'
export const COUPON_URL = '/api/coupon'
